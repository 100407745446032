@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500;700;900&display=swap");

* {
  box-sizing: border-box;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto";
  margin: 20px;
  padding: 0;
}

h1 {
  font-size: 22px;
  font-weight: 900;
  margin: 0px;
}

h2 {
  font-size: 20px;
  margin: 0px;
}

h3 {
  font-size: 18px;
  margin: 0px;
}

h4 {
  font-size: 16px;
  margin: 0px;
}

h5 {
  font-size: 14px;
  margin: 0px;
}

h6 {
  font-size: 12px;
  margin: 0px;
}

code {
  font-size: 1.2em;
}

ul {
  padding-left: 20px;
}

* {
  box-sizing: border-box;
}

label {
  font-size: 12px;
}

.inputFull {
  width: 100%;
  height: 42px;
  margin: 0px 0px 8px 0px;
  border-color: #a280b9;
  box-shadow: none;
  border-style: solid;
}

.bigCircle {
  background-color: #f1e7f4;
  width: 100px;
  height: 100px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bigCircle span {
  font-size: 60px;
}

/* Placeholder Elements 
//
//
//
//*/
.grey {
  background-color: rgb(205, 205, 205);
}

.aqua {
  background-color: rgb(142, 224, 251);
}

.pink {
  background-color: rgb(229, 129, 129);
}

.purple {
  background-color: rgb(124, 86, 127);
}

.imagePlaceholder {
  width: 40px;
  height: 40px;
  background: #999;
}

.placeHolder {
  width: 150px;
}

.padding8 {
  padding-top: 8px;
}

.padding16 {
  padding-top: 16px;
}

.vertColumn {
  flex-direction: column;
}

.paddingSide60 {
  padding: 24px;
  width: 60%;
}

.centeredElement {
  display: flex;
  justify-content: center;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.zeroStateImage {
  width: 100%;
}

/*
//
//
//
// Page Containers*/
.centralCard {
  background: #fff;
  border: 1px solid #999;
  width: 150px;
  height: 150px;
}

.horizontalContainer {
  display: flex;
  width: auto;
  justify-content: center;
}

.userPickerContainer {
  display: flex;
  width: auto;
  justify-content: center;
  height: 100%;
}

.userPickerDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  margin-bottom: 16px;
  gap: 16px;
  height: 90%;
}

.sideContainerTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0px 12px 0px;
}
.sideContainerTitleZeroLeft {
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin: 0px 14% 0px 0px;
}
.sideContainerTitleZeroRight {
  display: flex;
  flex-direction: row;
  padding: 0px 0px 0px 4%px;
}
.sideContainerTitleRight {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  transform: translateY(-20px);
}

.sideContainerFooter {
  display: flex;
  margin-top: 16px;
  justify-content: center;
  align-items: center;
}

.edgeContainer {
  flex-grow: 0;
  width: 100px;
  background: rgb(152, 146, 81);
}

.middleContainer {
  flex-grow: 1;
  display: flex;
  width: 200px;
  height: 200px;
  border-radius: 100%;
  border: 2px solid rgb(213, 192, 234);
  background: #e6e0e9;
  align-items: center;
  justify-content: center;
}

.arrowContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
}

.arrowImg {
  width: 80px;
}

.dollarSign,
.centAmount {
  font-size: 16pt;
}

.dollarAmount {
  font-size: 40pt;
  margin: 0px;
}

.count {
  font-size: 18pt;
  margin: 0px;
}

.dollarSign2,
.centAmount2 {
  font-size: 16pt;
}

.dollarAmount2 {
  font-size: 32pt;
  font-weight: 900;
}

.dollarSign3,
.centAmount3 {
  font-size: 12pt;
}

.dollarAmount3 {
  font-size: 24pt;
  font-weight: 300;
}

.setupHeader {
  display: flex;
  justify-content: space-between;
  justify-content: baseline;
}

.modal {
  position: fixed; /* Fix position on the screen */
  z-index: 9999; /* Make it appear above other elements */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scrolling if needed */
  background-color: rgb(0, 0, 0); /* Black background with opacity */
  background-color: rgba(0, 0, 0, 0.4); /* Black background with opacity */
  display: flex; /* Center the content */
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 30%; /* Define the width of modal content */
  text-align: center;
}

.avatarSelectGrid {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(100px, 1fr)
  ); /* Adjust the '100px' value to the size of your images or desired column width */
  gap: 32px; /* This provides the 8px gap between the images */
  overflow-y: auto;
  padding: 32px; /* To provide space around the grid, which can be adjusted as needed */
  align-items: stretch;
}

.avatar {
  width: 100%; /* or any size you prefer */
  height: auto; /* or any size you prefer */
  cursor: pointer;
}

.avatarSelected {
  border: 3px solid #ff0000; /* change as needed */
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.45);
}

.acctAvatarEdit {
  width: 100px; /* or any size you prefer */
  height: auto; /* or any size you prefer */
}

.selectedAcctAvatar {
  width: 100px; /* or any size you prefer */
  height: auto; /* or any size you prefer */
  border: 3px solid #ff0000; /* change as needed */
}

.defaultAcctAvatar {
  width: 100px; /* or any size you prefer */
  height: auto; /* or any size you prefer */
}

.countingAvatar {
  width: 300px; /* or any size you prefer */
  height: 300px; /* or any size you prefer */
  object-fit: cover;
}

.piggyBankContainer {
  display: flex; /* use Flexbox for the layout */
  justify-content: center; /* space out the columns */
  padding: 20px; /* add some space around the container */
}

.ftueOverview {
  display: flex;
  flex-direction: row;
  height: 90vh;
  align-items: center;
}

.ftueImage {
  width: calc(90%);
  max-width: 800px;
}

.modalActionContents2 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 8px;
  max-height: calc(100% - 170px);
  overflow-y: auto;
  align-items: stretch;
}

.ftueColumn-1 {
  flex: 2;
  padding: 20px;
}

.ftueColumn-2 {
  display: flex;
  flex-direction: column;
  flex: 1.5;
}

.ftueColumn-2 h2 {
  font-size: 24px;
  font-weight: 300;
  margin: 0px;
}

.FTUEimages {
  width: 100%;
}

.ftueContentsCent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
  height: 100%;
  padding: 0px 16px 0px 16px;
  gap: 8px;
}

.column-1,
.column-2 {
  flex: 1; /* make both columns take up equal space */
  padding: 20px; /* add some space around the content in each column */
}

.jobColumn-1,
.jobColumn-2 {
  flex: 1; /* make both columns take up equal space */
  padding: 0px 20px 20px 1px; /* add some space around the content in each column */
}

.button-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 90px));
  gap: 10px;
  padding-top: 60px;
}

.stickyFooter {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 0px 20px;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  background-color: #a280b9;
  color: white;
  border-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  border-style: solid;
}

.button:hover {
  background-color: rgb(107, 72, 130);
}

.button:active {
  background-color: #a280b9;
}

.button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.3);
}

.button-disabled {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  background-color: rgb(210, 191, 224);
  color: white;
  border-radius: 4px;
  cursor: default;
  box-shadow: none;
  border-color: transparent;
}

.buttonStyle2 {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  background-color: #c3a6d5;
  color: white;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: none;
  outline: none;
  border-color: transparent;
}

.buttonStyle2:hover {
  background-color: rgb(107, 72, 130);
}

.buttonStyle2:active {
  background-color: #a280b9;
  box-shadow: none;
  outline: none;
}

.buttonStyle2:focus {
  outline: none;
  box-shadow: none;
}

.buttonStyle3 {
  display: inline-block;
  padding: 0px 0px 0px 0px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  background-color: #c3a6d5;
  color: white;
  border-radius: 48px;
  cursor: pointer;
  box-shadow: none;
  outline: none;
  width: 48px;
  height: 48px;
  border-color: transparent;
}

.buttonStyle3:hover {
  background-color: rgb(107, 72, 130);
}

.buttonStyle3:active {
  background-color: #a280b9;
  box-shadow: none;
  outline: none;
}

.buttonStyle3:focus {
  outline: none;
  box-shadow: none;
}

.buttonStyle4 {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  width: 300px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  background-color: #c3a6d5;
  color: white;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: none;
  outline: none;
  border-color: transparent;
}

.buttonStyle4:hover {
  background-color: rgb(107, 72, 130);
}

.buttonStyle4:active {
  background-color: #a280b9;
  box-shadow: none;
  outline: none;
}

.buttonStyle4:focus {
  outline: none;
  box-shadow: none;
}

.buttonStyle5 {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  width: 300px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  background-color: white;
  color: rgb(107, 72, 130);
  border-radius: 25px;
  cursor: pointer;
  box-shadow: none;
  outline: none;
  border-color: transparent;
}

.buttonStyle5:hover {
  background-color: rgb(107, 72, 130);
  color: white;
}

.buttonStyle5:active {
  background-color: #a280b9;
  box-shadow: none;
  outline: none;
}

.buttonStyle5:focus {
  outline: none;
  box-shadow: none;
}

.borderless {
  font-size: 16px;
  font-weight: bold;
  text-decoration: underline;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
}

.didItButton {
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: white;
  border-radius: 48px;
  cursor: pointer;
  background-color: b49ac5;
  border-color: transparent;
  width: 48px;
  height: 48px;
}

.didItInactive {
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  background-color: #f7f4f8;
  color: #b49ac5;
  border-radius: 48px;
  cursor: pointer;
  height: 100%;
  border-color: transparent;
  width: 48px;
  height: 48px;
}

.didItButton:hover {
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  background-color: #b49ac5;
  color: white;
  border-radius: 48px;
  cursor: pointer;
}

.iconCheckmark,
.iconCircle {
  font-size: 28px;
  vertical-align: middle;
  margin: 0px;
  padding: 0px;
}

.iconCheckmark {
  color: #b49ac5; /* same color as .didItInactive text */
  margin: 0px;
  padding: 0px;
}

.iconCircle {
  /* same color as .didItButton text */
  color: #a279bd;
}

.framelessButton {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  background-color: white;
  color: #a280b9;
  border-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  border-style: solid;
}

.framelessButton:hover {
  text-decoration: underline;
  color: #7e6092;
}

/* Everything here is for the status container 
//
//
*/
.horizontalContainerFull {
  display: flex;
  flex-direction: row;
  width: 98vw;
  height: 90vh;
  justify-content: space-between;
}

/* Medium screens */
@media (min-width: 1200px) {
  .horizontalContainerFull {
    gap: 45px;
  }
}

/* Large screens */
@media (min-width: 1400px) {
  .horizontalContainerFull {
    gap: 85px;
  }
}

.guideContentElement {
  position: fixed; /* sticky to the bottom */
  bottom: 0; /* at the footer */
  left: 0;
  right: 0;
  z-index: 3; /* ensure it's above other content */
}

.expandFull {
  display: flex;
  width: 100%;
  justify-content: center;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  width: 200px;
  gap: 10px;
  transition: all 0.5s ease-in-out;
  transform: translateY(3%);
}

.contentWrapper.slideUp {
  height: 45vh;
}

.statusContainer {
  flex-grow: 0;
  display: flex;
  width: 100px;
  height: auto;
  align-items: center;
  justify-content: center;
}

.statusContainer img {
  margin-right: 20px;
}

.statusAvatar {
  width: 42px;
  object-fit: cover;
}

.youHaveAvatar {
  width: 140px;
  object-fit: cover;
}

.avatarBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.youHave {
  line-height: 1;
  margin: 8px 0 8px 0;
  display: flex;
}

.youHaveButton {
}

.introTitle {
  height: auto;
  width: 300px;
  border: blueviolet;
  border-radius: 12px;
  border: 1px solid #999;
  justify-content: center;
  text-align: center;
  padding: 24px;
}

.introImage {
  transform: translateY(-120px);
  height: 30px;
}

.countImage {
  width: 90%;
  transform: translateY(100px);
}

.countVertCent {
  display: flex;
  align-items: center;
  height: 100%;
}

.introTitleWSubText {
  height: auto;
  width: 300px;
  border: blueviolet;
  border-radius: 12px;
  border: 1px solid #999;
  justify-content: center;
  text-align: center;
  padding: 60px 32px 16px 32px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.introTitleWSubText h3 {
  margin: 0px;
}
.introTitleWSubText p {
  margin: 4px 0px 16px 0px;
}

.inputCell {
  width: 100%;
  height: 45px;
}

.introTitleFooter {
  display: Flex;
  flex-direction: column;
  padding: 24px 0px 8px 0px;
  gap: 16px;
}

.imageGridWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centeredContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 95vh;
}

.titleDefault {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 48px 0 36px 0;
}

.paddingBankEntry {
  margin: 150px 0px 0px 0px;
}

.cardDefault {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 36px 10px;
}
/* 
//
//
//
// Accounts Container */
/**/
.accountsPageElement {
  position: absolute;
  top: 30vh;
  left: 0;
  right: 0;
  bottom: 25vh; /* this will stretch the div to cover the parent completely */
  z-index: 2; /* ensure this is above the content */
}

.accountsContainer {
  top: calc(
    25vh
  ); /* This will position the container 200px down from the top of the viewport */
  left: 0; /* This will align it to the left side of the viewport */
  right: 0; /* This will stretch it to take the full width */
  overflow-y: auto; /* Allows for vertical scrolling if the content exceeds the height of the viewport */
  max-height: calc(
    100vh - 200px
  ); /* Ensures that the box does not go beyond the viewport height, considering its top positioning */
}

.accounts-enter {
  opacity: 0;
}

.accounts-enter-active {
  opacity: 1;
  transition: opacity 700ms ease-in;
}

.accounts-exit {
  opacity: 1;
  transition: opacity 300ms ease-out;
}

.accounts-exit-active {
  opacity: 0;
}

.accounts-enter .accountsContainer {
  transform: translateY(100px);
}

.accounts-enter-active .accountsContainer {
  transform: translateY(0);
  transition: transform 500ms ease-in-out;
}

.accounts-exit .accountsContainer {
  transform: translateY(0);
}

.accounts-exit-active .accountsContainer {
  transform: translateY(100px);
  transition: transform 500ms ease-in-out;
}

.centeredAccountElements {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashedLine {
  width: 2px; /* Width of the dashed line */
  height: 30px; /* Adjust this value to set the length of the line */
  background: repeating-linear-gradient(
    to bottom,
    #a280b9 0px,
    #a280b9 5px,
    transparent 5px,
    transparent 10px
  );
}

.acctColumnsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.accountsColumn-1 {
  align-items: center;
  flex-direction: column;
}

.fullCenter {
  padding: 16px 0px 0px 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.hide {
  visibility: hidden;
}

.show {
  visibility: visible;
}

/* 
//
//
//
// Account Card Elements */
/**/
.accountCard {
  height: auto;
  width: 250px;
  background-color: white;
  border-radius: 12px;
  border: 2px solid #a280b9;
  display: flex;
  flex-direction: row;
  gap: 1px;
}

.acPrimary {
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.acPrimary:hover {
  width: 70%;
  display: flex;
  flex-direction: row;
  text-decoration: underline;
  cursor: pointer;
}

.acSecondary {
  display: flex;
  width: 30%;
  background-color: #dbc4ea;
  border-radius: 0px 10px 10px 0px;
  align-items: center;
}
.acSecondary:hover {
  width: 30%;
  background-color: #b79bca;
  border-radius: 0px 10px 10px 0px;
}
.acTertiary {
  width: 50%;
  border-radius: 0px 10px 10px 0px;
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding: 0px 12px 0px 0px;
  gap: 4px;
  align-items: center;
}

.accountIcon {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.accountTitle {
  height: 60px;
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  gap: 4px;
}

.accountAmount {
  height: 50px;
  display: flex;
  text-align: left;
  align-items: center;
  margin-left: 8px;
}

.accountTitle H5 {
  color: #814fa2;
  font-weight: 500;
  font-size: 16px;
  margin: 0px;
}

.accountTitle p {
  color: black;
  font-weight: 400;
  font-size: 12px;
  margin: 0px;
}

/* 
//
//
//
// Job & Earnings Container Elements */
/**/
.leftMainView {
  display: flex;
  flex-direction: row;
}

.flexContainer {
  transform: translateY(60px);
  max-height: calc(100% - 60px);
  display: flex;
  flex-direction: row;
  overflow: auto;
  min-width: 275px;
  margin: 0px 16px 0px 16px;
  gap: 16px;
}

.jobsList {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.jobsListWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1; /* This makes the wrapper grow and shrink within its parent */
}

.flexContainer.overflowing .jobsListWrapper {
  justify-content: flex-start; /* Align content to start when overflowing */
}

.rightMainView {
  display: flex;
  flex-direction: row;
}

.earningsContainer {
  left: 0; /* This will align it to the left side of the viewport */
  right: 0; /* This will stretch it to take the full width */
  bottom: 0;
  z-index: 100;
  position: fixed;
  display: flex;
  justify-content: center;
}

.earningsParent {
  width: auto;
}

/* 
//
//
//
// Job Card Elements */
/**/
.jobCard {
  height: auto;
  width: 280px;
  background-color: white;
  border-radius: 12px;
  border: 2px solid #e6e0e9;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  padding: 0px 8px 0px 8px;
  gap: 8px;
}

.earningsCell {
  background-color: #e6e0e9;
  border-radius: 16px;
  position: relative; /* This ensures it takes up space, but also can be stacked using z-index */
  z-index: 1;
  width: auto;
  height: 100px;
  margin: 50px 16px 30px 190px;
  display: flex;
  flex-direction: column;
  padding: 12px 16px 0px 16px;
}

.earningsAvatar {
  position: absolute; /* Absolutely position this, so it can overlap */
  z-index: 2;
  transform: translateY(-20px);
}

.earningsIcon {
  width: 150px;
  object-fit: cover;
  transform: translateY(35px) translateX(30px);
}

.earningsIcon2 {
  width: 250px;
  object-fit: cover;
  transform: translateY(-35px);
}

.earningSummary {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}

.earningSummary {
  display: flex;
  flex-direction: column;
}

.earningsControl {
  display: flex;
  flex-direction: row;
  gap: 32px;
}

.earningSummaryControl {
  display: flex;
  align-items: center;
}

.earningModalCont {
  width: auto;
}

.floatingX {
  width: 100%;
  display: flex;
  justify-content: end;
  position: absolute;
  z-index: 3;
  padding: 40px 32px 0px 0px;
}

.earningsAvatar2 {
  position: absolute; /* Add this line */
  top: 0px; /* Set to 0 to position at the top of .earningModalHeadCont */
  left: 0; /* Set to 0 to position at the start/left of .earningModalHeadCont */
  width: 100%; /* Make it full width of parent */
  display: flex;
  justify-content: end;
  padding: 0px 16% 0px 0px;
  z-index: 2; /* Ensures it's above .earningModalHeader */
}

.stopGetParentModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  gap: 12px;
}

.stopGetParentAvatar {
  width: 80%;
}

.earnOwePigAvatar {
  position: absolute;
  width: 220px;
  transform: translateY(-150px) translateX(100px);
}

.earnPayParentAvatar {
  position: absolute;
  width: 180px;
  transform: translateY(-140px) translateX(100px);
}

.summaryPigAvatar {
  width: 220px;
  transform: translateX(100px);
}

.summaryPigAvatar2 {
  width: 220px;
}

.earningModalHeader {
  left: 0; /* Set to 0 to position at the start/left of .earningModalHeadCont */
  width: 100%; /* Make it full width of parent */
  background-color: white;
  border-radius: 10px 10px 0px 0px;
  z-index: 1; /* Keeps it below .earningsAvatar2 */
  display: flex;
  justify-content: flex-end;
  padding: 16px 16px 8px 0px;
}
.earningModalBodyDefault {
  display: flex;
  flex-direction: column;
  height: auto;
  background-color: white;
  border-radius: 0px 0px 10px 10px;
  min-width: 460px;
  min-height: 450px;
  padding: 0px 24px 0px 24px;
}

.earningModalBodyContent {
  width: 100%;
  height: auto;
  text-align: center;
  padding: 24px 32px 8px 32px;
}

.earningModalConfirmationContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  text-align: center;
}

.earningModalConfirmationContent h2 {
  font-weight: 500;
  font-size: 32px;
  margin: 150px 0px 0px 0px;
}

.earningModalPayNowHeader {
  display: flex;
  flex-direction: row;
  margin: 140px 0px 0px 0px;
  align-items: center;
  gap: 16px;
}

.earningModalCinformationAvatar {
  width: 350px;
  transform: translateY(-135px);
  position: absolute;
}

.earningModalFooter {
  width: 100%; /* Make it full width of parent */
  height: auto;
  display: flex;
  justify-content: space-between;
  padding: 32px 0px 24px 0px;
  margin-top: auto;
}
.earningModalcenterFooter {
  width: 100%; /* Make it full width of parent */
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0px 0px 24px 0px;
  align-items: center;
  text-align: center;
  margin-top: auto;
}

.youOweEarnHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.div-line {
  height: 1px;
  background-color: black;
  width: 100%;
}

.youOweEarnListItems {
  width: 100%;
  padding-top: 16px;
}

.earningModalSpeechBubble {
  background: #3a3a3a;
  color: white;
  padding: 28px 16px 24px 16px;
  text-align: center;
  border-radius: 24px;
  position: relative; /* Add this to position the arrow correctly */
  margin-top: 8px;
}

.earningModalSpeechBubbleTwoColumn {
  background: #3a3a3a;
  color: white;
  padding: 28px 16px 24px 16px;
  text-align: center;
  border-radius: 24px;
  position: relative; /* Add this to position the arrow correctly */
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 32px;
}

.earningModalSpeechBubble h2,
.earningModalSpeechBubbleTwoColumn h2 {
  font-weight: 300;
  font-size: 24px;
  margin: 0px 0px 8px 0px;
}

.earningModalSpeechBubble p {
  font-weight: 300;
  margin: 16px 0px 8px 0px;
}

.earningModalSpeechBubble h3 {
  font-weight: 300;
  font-size: 40px;
  margin: 0px 0px 8px 0px;
}

.earningModalSpeechBubble::before {
  content: ""; /* This is necessary for the pseudo-element to show */
  position: absolute;
  bottom: -32px; /* This places the arrow just below the bubble */
  left: 70%; /* This will center the arrow to the middle of the bubble */
  transform: translateX(
    -50%
  ); /* This ensures the arrow remains centered regardless of its width */
  width: 46px; /* Adjust based on how wide you want the arrow to be */
  height: 33px; /* Adjust based on how tall you want the arrow to be */
  background: #3a3a3a;
  clip-path: polygon(100% 0%, 0 0%, 0% 100%); /* This shapes the arrow */
}

.earningModalSpeechBubbleTwoColumn::before {
  content: ""; /* This is necessary for the pseudo-element to show */
  position: absolute;
  bottom: -32px; /* This places the arrow just below the bubble */
  left: 70%; /* This will center the arrow to the middle of the bubble */
  transform: translateX(
    -50%
  ); /* This ensures the arrow remains centered regardless of its width */
  width: 46px; /* Adjust based on how wide you want the arrow to be */
  height: 33px; /* Adjust based on how tall you want the arrow to be */
  background: #3a3a3a;
  clip-path: polygon(100% 0%, 0 0%, 0% 100%); /* This shapes the arrow */
}

.earningModalTitleText {
  padding: 0px 0px 0px 0px;
}

.earningModalTitleText h1 {
  font-weight: 300;
  font-size: 25px;
  margin: 0px 0px 8px 0px;
}

.earningModalTitleText h2 {
  font-weight: 500;
  font-size: 32px;
  margin: 0px 0px 0px 0px;
}

.earningModalTotal {
  margin: 30px 0px 0px 0px;
  text-align: right;
}

.earningModalContents {
  margin: 30px 0px 0px 0px;
  text-align: right;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.earningModalCantPayContents {
  margin: 0px 0px 0px 0px;
  text-align: center;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 16px;
}

.earningModalPaidDebtContents {
  margin: 16px 0px 16px 0px;
  text-align: center;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.earningModalWhyMattersCont {
  margin: 16px 0px 24px 16px;
  text-align: left;
}

.getPaidLICont {
  display: flex;
  padding: 8px 8px 8px 8px;
  justify-content: space-between;
}

.getPaidCount {
  display: flex;
  gap: 40px;
  align-items: center;
  text-align: center;
}

.getPaidLIprefix {
  font-size: 12px;
  margin: 0px;
}

.getPaidLItitle {
  font-size: 24px;
  font-weight: 500;
  margin: 0px;
}

.countCircle {
  display: flex;
  background-color: rgb(235, 216, 241);
  border-radius: 28px;
  height: 50px;
  width: 50px;
  align-items: center;
  justify-content: center;
}

/*
//
//
//
// Loan Workflows */
.borrowColumnContainer {
  display: flex;
  flex-direction: row;
  max-width: 800px;
}

.borrowImages {
  width: 400px;
}

.borrowColumn-1 {
  flex: 1.3;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.borrowColumn-2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.borrowWorkflowTitle {
  width: 90%;
  transform: translateY(-45px);
}

.borrowWorkflowTitle H2 {
  font-weight: 500;
  font-size: 32px;
  margin: 0px 0px 0px 0px;
}

.borrowControlsStack {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 24px;
}

.loanCard {
  height: 60px;
  width: 280px;
  background-color: #e6e0e9;
  border-radius: 12px;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: row;
  padding: 8px 16px 8px 8px;
  justify-content: space-between;
}

.primaryLoanContents {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.primaryLoanContents:hover {
  text-decoration: underline;
  cursor: pointer;
}

.loanIcon {
  width: 40px;
  display: flex;
}

.loanDetails {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 4px;
}

.loanDetails h5 {
  color: #814fa2;
  font-weight: 500;
  font-size: 16px;
}

.loanDetails p {
  margin: 0px;
  color: black;
  font-weight: 400;
  font-size: 12px;
}

.payButton {
  display: inline-block;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  background-color: #a280b9;
  color: white;
  border-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  border-style: solid;
}

.payButton:hover {
  background-color: rgb(107, 72, 130);
}

.loanInputField {
  height: 45px;
  margin: 16px 0px 0px 0px;
}

.loanDaySelectorContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: 16px 0px 0px 0px;
}

.loanReceipt {
  border-radius: 12px;
  border: 2px solid #a280b9;
  padding: 16px;
  margin: 16px 0px 0px 0px;
}

.loanReceipt p {
  font-size: 18px;
  font-weight: 300;
  margin: 0px;
}

.loanReceipt span {
  font-weight: 800;
}

/*
//
//
//
// Account Edit Modal */

.actModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.actModalContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 80vw;
  max-height: 90vh;
}

.actEditModalSubContents {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.actEditModalSubContents p {
  font-size: 12px;
  margin: 8px 0px 8px 0px;
}

.createAccountRow {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.createAccountRowItem {
  width: 50%;
}

.modalTitleControl {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.modalImageSelection {
  display: flex;
  flex-direction: grid;
  justify-content: space-between;
  align-items: center;
  overflow: scroll;
}
.avatarModalSelection {
  align-items: center;
  max-height: 50vh;
  overflow-y: auto;
}
.actModalColumns {
  display: flex;
  flex-direction: row;
}

.actColumn-1,
.actColumn-2 {
  flex: 1; /* make both columns take up equal space */
  padding: 20px; /* add some space around the content in each column */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.actAvatar {
  max-width: 250px;
}

.flagCell {
  background-color: #3a3a3a;
  border-radius: 16px;
  position: relative; /* This ensures it takes up space, but also can be stacked using z-index */
  z-index: 1;
  width: 500px;
  height: 100px;
  margin: 50px 16px 30px 0px;
  display: flex;
  flex-direction: row;
  padding: 12px 24px 16px 190px;
  color: white;
}

.levelFlagParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 40%;
  min-width: 460px;
}

.levelFlagAvatar {
  position: absolute;
  max-width: 160px;
  z-index: 20;
}

.levelSummary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 190px;
}
/*
//
//
//
Generic Level Up Flag
*/
.levelFlagAvatar2 {
  position: absolute;
  max-width: 250px;
  z-index: 20;
  transform: translateY(-65px);
}

.darkCellBG {
  background-color: black;
  border-radius: 16px;
  position: relative; /* This ensures it takes up space, but also can be stacked using z-index */
  z-index: 1;
  width: 100%;
  height: 100px;
  margin: 50px 16px 30px 10px;
  display: flex;
  flex-direction: row;
  padding: 0px 16px 0px 140px;
  align-items: center;
  gap: 8px;
}

.darkCellBG h1 {
  color: white;
}
/*
//
//
//
Job Modal here
*/
.jobModal {
  display: flex;
  background-color: white;
  border-radius: 8px;
  max-width: 800px;
}

.roundColumn1 {
  display: flex;
  flex: 0.8;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px 0px 0px 8px;
  padding: 32px 32px 24px 32px;
  height: calc(100vh - 200px);
}

.roundColumn2 {
  flex: 1;
  padding: 32px 32px 32px 32px;
  border-radius: 0px 8px 8px 0px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 200px);
}

.jobModal h1 {
  font-size: 24px;
  font-weight: 700;
  margin: 0px;
}

.jobModal h2 {
  font-size: 30px;
  font-weight: 500;
  margin: 0px;
}

.jobModal strong {
  font-weight: 900;
  font-size: 14px;
}

.jobModal p {
  font-weight: 300;
  font-size: 14px;
}

.jobModalTitleContainer {
  height: auto;
  padding-bottom: 16px;
}

.jobModalExitContainer {
  width: 100%;
  text-align: right;
  padding-bottom: 16px;
}

.jobWorkflowAvatar {
  width: 250px;
}

.modalSubTitle {
  width: 100%;
  text-align: center;
}

.modalActionSizer {
  height: 100%;
  overflow-y: auto;
}

.modalActionContents {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: calc(100% - 150px);
  overflow-y: auto;
}

.modalFooterControls {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  bottom: 0;
  justify-content: space-between;
}
.modalFooterControls2 {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  bottom: 0;
  justify-content: space-between;
}
.modalFooterControlSingle {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  bottom: 0;
  justify-content: end;
}

.doubleButtonFooter {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
}

.listWrapper {
  padding: 20px 0px 20px 0px; /* This adds 20px padding to the top and bottom */
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-sizing: border-box;
}

.listWrapper > button:first-child {
  margin-top: 0;
}

.jobChoice {
  height: 40px;
  width: 250px;
  background-color: white;
  border-radius: 12px;
  border: 2px solid #a280b9;
  text-align: left;
  padding: 0px 8px 0px 16px;
  flex-shrink: 0;
}

.jobChoiceSelected {
  height: 40px;
  width: 250px;
  color: white;
  background-color: #543867;
  border-radius: 12px;
  border: 2px solid #a280b9;
  text-align: left;
  padding: 0px 8px 0px 16px;
}

.jobChoice:hover {
  height: 40px;
  width: 250px;
  background-color: #c6b5d2;
  border-radius: 12px;
  border: 2px solid #8b6ba1;
  text-align: left;
  padding: 0px 8px 0px 16px;
  gap: 8px;
}

.modalCounter {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

/* 
//
//
//
// Expense Elements */
.expenseCellAmt {
  background-color: #a280b9;
  border-radius: 0px 10px 10px 0px;
  padding: 0px 8px 0px 8px;
  color: white;
}

.expenseCellLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0px 0px 4px;
}

.expenseCellLeft:hover {
  text-decoration: underline;
  cursor: pointer;
}

.expenseCycleIcon {
  width: px;
}

.expenseSubTitle p {
  color: black;
  font-weight: 400;
  font-size: 12px;
  margin: 0px;
}

/* 
//
//
//
// Header Elements */
/**/
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  background-color: white;
}
.headerContents {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  height: 70px;
  padding: 3px 16px 0px 16px;
}

.dropdownMenu {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 12px;
  padding: 8px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  transform: translateX(-200px);
}

.dropdownMenu button span {
  color: #888;
  font-weight: 100;
  font-style: italic;
}

.dropdownMenu button {
  display: block;
  height: 45px;
  width: 200px;
  text-align: left;
  border-color: transparent;
  background-color: white;
}

.dropdownMenu button:hover {
  background-color: #c6b5d2;
}
/* 
//
//
//
// Menu Elements */
/**/
.menuOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(203, 203, 203, 0.696);
  align-items: center;
  z-index: 9999;
}

.menuContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  width: 320px;
  height: 100vh;
}

.menuBody {
  height: 100%;
}
.menuSettings {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 16px;
}

.scrollableContent {
  overflow: auto;
  min-height: calc(100vh - 500);
}

.menuFooter {
  margin-top: auto;
}

.menuUserDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  margin-bottom: 16px;
  gap: 16px;
  height: 20%;
}

.menuSettingsExpanded {
  overflow: auto;
}

.userNameAndPic {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.userTiles {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.userPickerOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  justify-content: flex-start;
  align-items: center;
  z-index: 9999;
}

.userPickerClose {
  padding: 16px;
}

.menuAvatar {
  width: 90px;
}

.menuContainer h1 {
  font-size: 24px;
  font-weight: 700;
  margin: 0px;
}

.menuContainer h2 {
  font-size: 30px;
  font-weight: 500;
  margin: 0px;
}

.menuContainer strong {
  font-weight: 900;
  font-size: 14px;
}

.menuContainer p {
  font-weight: 300;
  font-size: 14px;
}

.pickerActiveUser {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  gap: 8px;
  padding: 8px;
  border-radius: 16px;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
  border-color: #543867;
  border-width: 2px;
  border-style: solid;
}

.pickerInactiveUser {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  gap: 8px;
  padding: 8px;
  border-radius: 16px;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
}

.doubleConfirmationModal {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  width: 340px;
  height: auto;
  text-align: center;
  gap: 16px;
}
/* 
//
//
//
// New Job Card */
/**/
.newJobCardCell {
  width: 200px;
  height: 212px;
  border-radius: 16px;
  border-color: #878787;
  border-width: 2px;
  border-style: solid;
}

.newJobCardCell.selected {
  border-color: #00aaff; /* or any color to indicate selection */
}

.jobPickerSlotmachine {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 800px;
  max-height: 70vh;
}

.cardShuffleBody {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 8px;
  align-items: stretch;
  margin-top: 32px;
}

.cardShuffleContainer {
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding: 0px 0px 24px 0px;
}

.starburstContainer {
  display: flex;
  align-items: center;
  transform: translateX(20px);
}
.starburstValue {
  width: 75px;
  text-align: center;
  z-index: 1;
}

.starburstValue p {
  margin: 0px;
}

.jobFrequency {
  margin: 12px 0px 0px 16px;
}

.jobCardBody {
  display: flex;
  flex-direction: column;
  height: 140px;
  align-items: flex-end;
  justify-content: flex-end; /* push children to the bottom */
}
.jobTitle {
  text-align: center;
  width: 100%;
  margin-top: 16px;
}

.jobSelectCheck {
  display: flex;
  text-align: center;
  justify-content: center;
  width: 100%;
}

.starburst {
  position: absolute;
  text-align: center;
  width: 75px;
  height: 75px;
  background-color: #d9d9d9; /* You can change the color as needed */
  clip-path: polygon(
    100% 50%,
    88.64% 60.35%,
    93.3% 75%,
    78.28% 78.28%,
    75% 93.3%,
    60.35% 88.64%,
    50% 100%,
    39.65% 88.64%,
    25% 93.3%,
    21.72% 78.28%,
    6.7% 75%,
    11.36% 60.35%,
    0% 50%,
    11.36% 39.65%,
    6.7% 25%,
    21.72% 21.72%,
    25% 6.7%,
    39.65% 11.36%,
    50% 0%,
    60.35% 11.36%,
    75% 6.7%,
    78.28% 21.72%,
    93.3% 25%,
    88.64% 39.65%
  );
}

.jobCardHeaderBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.checkmarkCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #00aaff; /* or any color you prefer for the circle background */
  color: white; /* color for the checkmark */
  font-size: 30px; /* size of the checkmark */
  margin-right: 10px; /* spacing between checkmark and other content */
}

.shuffleButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #c3a6d5;
  color: white;
  border-width: 0px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  padding-top: 10px;
  gap: 2px;
}

.shuffleButton:hover {
  background-color: rgb(107, 72, 130);
}

.centerShuffleButton {
  padding-top: 68px;
}

.timeToPaydayCell {
  background-color: #ececec;
  border-radius: 16px;
  position: relative; /* This ensures it takes up space, but also can be stacked using z-index */
  z-index: 1;
  width: auto;
  height: 100px;
  margin: 0px 0px 30px 0px;
  display: flex;
  flex-direction: row;
  padding: 12px 24px 0px 24px;
  gap: 24px;
}

.timeToPaydayImage {
  transform: translateY(-45px);
}

.timeIcon {
  width: 100px;
}

.payDayIcon {
  width: 150px;
}

.paydayCell {
  background-color: #19171a;
  border-radius: 16px;
  position: relative; /* This ensures it takes up space, but also can be stacked using z-index */
  z-index: 1;
  width: auto;
  height: 100px;
  margin: 0px 0px 30px 0px;
  display: flex;
  flex-direction: row;
  padding: 12px 24px 0px 8px;
  gap: 24px;
  color: white;
}

.payDayAvatar {
  transform: translateY(-40px);
}

.payDayLaunchButton {
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;
}
